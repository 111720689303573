import React from "react";
import styled from "@emotion/styled";
// components
import { ItemImgWrapper } from "../wrapper/itemImgWrapper";
import { ItemInfoWrapper } from "../wrapper/itemInfoWrapper";
import { ItemIntroWrapper } from "../wrapper/itemIntroWrapper";
// utils
import { Color } from "../../utils/color";
import { ItemLists } from "../../utils/itemLists";
// img
import ImgItem01 from "../../images/item_01.jpg";
import ImgItem02 from "../../images/item_02.jpg";
import ImgItem03 from "../../images/item_03.jpg";
// imglist
const imgList = [
  { id: "01", img: ImgItem01 },
  { id: "02", img: ImgItem02 },
  { id: "03", img: ImgItem03 },
];
// id === 1を抽出
const selectItems = ItemLists.filter((Item) => {
  return Item.id === 1;
});

export const ItemSection = () => {
  return (
    <SSection>
      <SContainer>
        <ItemImgWrapper imgList={imgList} />
        <ItemInfoWrapper selectItems={selectItems} />
        <ItemIntroWrapper>
          商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト
          <br />
          <br />
          商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト
          <br />
          <br />
          商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト商品の説明サンプルテキスト
        </ItemIntroWrapper>
      </SContainer>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  border-bottom: 1px solid ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
  /*  */
  display: grid;
  grid-template-columns: 672px 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "img info"
    "intro info";
  gap: 1rem;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "img"
      "info"
      "intro";
    gap: 0.5rem;
  }
`;
