import React from "react";
// components
import { DefaultLayout } from "../../components/layout/defaultLayout";
import { BreadcrumbBar } from "../../components/bar/breadcrumbBar";
import { SaleSection } from "../../components/section/saleSection";
import { PickupSection } from "../../components/section/pickupSection";
import { BrandSection } from "../../components/section/brandSection";
import { GenreSection } from "../../components/section/genreSection";
import { ItemSection } from "../../components/section/itemSection";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "製品一覧", url: "/items" },
  { txt: "製品01", url: "/items/item01" },
];

const Item01Page = () => {
  return (
    <DefaultLayout pageTitle="製品01">
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <ItemSection />
      <SaleSection />
      <PickupSection />
      <BrandSection />
      <GenreSection />
    </DefaultLayout>
  );
};

export default Item01Page;
