import React from "react";
import styled from "@emotion/styled";
// utils
import { Color } from "../../utils/color";

export const AddCartBtn = () => {
  const onClickAddCart = () => {
    alert("カートに追加しました。このサイトはデモサイトです。");
  };
  return (
    <SBtn onClick={onClickAddCart}>
      <i className="material-icons-outlined">shopping_cart</i>
      <span>カートに追加する</span>
    </SBtn>
  );
};

const SBtn = styled.button`
  height: 64px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${Color.black};
  background-color: ${Color.black};
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    margin-left: 0.5rem;
  }
  i,
  span {
    color: ${Color.white};
    transition: all 0.2s;
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      border-color: ${Color.gray200};
      background-color: ${Color.white};
      i,
      span {
        color: ${Color.gray400};
      }
    }
  }
  @media screen and (max-width: 1023px) {
    &:hover {
      border-color: ${Color.gray200};
      background-color: ${Color.white};
      i,
      span {
        color: ${Color.gray400};
      }
    }
  }
`;
