import React from "react";
import styled from "@emotion/styled";
// utils
import { Color } from "../../utils/color";

export const ItemIntroWrapper = (props) => {
  return <SWrapper>{props.children}</SWrapper>;
};

const SWrapper = styled.div`
  grid-area: intro;
  margin-top: 1rem;
  padding: 2rem;
  padding-right: 1rem;
  border-top: 1px dashed ${Color.gray100};

  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;
