import React from "react";
import styled from "@emotion/styled";
import { useState } from "react";

export const ItemImgWrapper = (props) => {
  const [isSelect, setIsSelect] = useState("01");
  const onClickChangeImg = (e) => {
    const target = e.currentTarget.getAttribute("data-img-id");
    setIsSelect(target);
  };
  return (
    <SWrapper>
      <div className="btn-area">
        {props.imgList.map(({ id, img }) => {
          return (
            <button onClick={onClickChangeImg} data-img-id={id} key={id}>
              <img src={img} alt="item" />
            </button>
          );
        })}
      </div>
      <div className="img-area" data-img-select={isSelect}>
        {props.imgList.map(({ id, img }) => {
          return <img src={img} alt="item" key={id} data-is-select={id} />;
        })}
      </div>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  grid-area: img;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 80px 1fr;
  grid-template-areas: "btnarea imgarea";
  gap: 1rem;
  .btn-area {
    grid-area: btnarea;
    display: flex;
    flex-direction: column;
    button {
      width: 80px;
      height: 80px;
      margin-bottom: 1rem;
      border: none;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .img-area {
    grid-area: imgarea;
    width: 100%;
    aspect-ratio: 1/1;
    img {
      width: 100%;
      aspect-ratio: 1/1;
      display: none;
      object-fit: cover;
      object-position: center;
    }
    &[data-img-select="01"] {
      img {
        &:nth-of-type(1) {
          display: inline-block;
        }
      }
    }
    &[data-img-select="02"] {
      img {
        &:nth-of-type(2) {
          display: inline-block;
        }
      }
    }
    &[data-img-select="03"] {
      img {
        &:nth-of-type(3) {
          display: inline-block;
        }
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    grid-template-rows: auto 80px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "imgarea"
      "btnarea";
    gap: 0.5rem;
    .btn-area {
      flex-direction: row;
      button {
        margin-bottom: 0;
        margin-right: 0.5rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .img-area {
      img {
      }
    }
  }
`;
