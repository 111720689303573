import React from "react";
import styled from "@emotion/styled";
// components
import { AddCartBtn } from "../button/addCartBtn";
import { FavoriteBtn } from "../button/favoriteBtn";
// utils
import { Color } from "../../utils/color";

export const ItemInfoWrapper = (props) => {
  return (
    <SWrapper>
      {props.selectItems.map(({ id, name, price, genre, brand }) => {
        return (
          <div key={id}>
            <span className="genre">{genre}</span>
            <span className="name">{name}</span>
            <span className="brand">{brand}</span>
            <span className="price">
              <span>価格：</span>¥ {price}
            </span>
          </div>
        );
      })}
      <SBtnArea>
        <AddCartBtn />
        <FavoriteBtn />
      </SBtnArea>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  grid-area: info;
  border-left: 1px dashed ${Color.gray100};
  padding: 0 2rem;
  div {
    display: flex;
    flex-direction: column;
    line-height: 1;
    .genre {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${Color.gray300};
    }
    .name {
      font-size: 2rem;
      font-weight: 700;
      color: ${Color.gray400};
      margin-top: 1rem;
    }
    .brand {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${Color.gray300};
      margin-top: 1rem;
    }
    .price {
      font-size: 1.5rem;
      font-weight: 500;
      color: ${Color.gray400};
      border-top: 1px solid ${Color.gray100};
      margin-top: 2rem;
      padding-top: 2rem;
      span {
        font-size: 1rem;
        font-weight: 400;
        color: ${Color.gray300};
        margin-right: 1rem;
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
    margin-top: 1rem;
    padding: 0;
    border-left: none;
  }
`;

const SBtnArea = styled.div`
  margin-top: 1rem;
  button {
    margin-top: 0.5rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
